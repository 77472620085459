import { ENV } from 'constants/env'

export default async function getTotalSupply(): Promise<number> {
  const uri = new URL(ENV.TS_URL)

  try {
    const totalSupply: number = await fetch(uri.toString()).then(async (res) => {
      const data = await res.json()
      return data
    })
    return totalSupply
  } catch (e) {
    console.error(e)
  }
  return 699_993_269.28
}
