import useSWR from 'swr'

import getAllPositions from 'api/vesting/getAllPosition'

export default function usePosition() {
  return useSWR(`allPositions`, () => getAllPositions(), {
    suspense: true,
    revalidateOnFocus: false,
  })
}
