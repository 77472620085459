import BigNumber from 'bignumber.js'
import classNames from 'classnames'

import Card from 'components/Card'
import DisplayCurrency from 'components/DisplayCurrency'
import { FormattedNumber } from 'components/FormattedNumber'
import Loading from 'components/Loading'
import TitleAndSubCell from 'components/TitleAndSubCell'
import { MARS } from 'constants/assets'
import { BN_ONE } from 'constants/math'
import useCirculatingSupply from 'hooks/useCirculatingSupply'
import usePrices from 'hooks/usePrices'
import useTotalSupply from 'hooks/useTotalSupply'
import { BNCoin } from 'types/classes/BNCoin'

const cardClasses = 'w-full px-2 py-6 text-center gradient-card-content'
const loaderClasses = 'w-20 h-6 mx-auto mb-1'

export default function Metrics() {
  const { isLoading: isLoadingPrices } = usePrices()
  const { data, isLoading } = useCirculatingSupply()
  const { data: totalSupply, isLoading: totalSupplyLoading } = useTotalSupply()

  return (
    <div className='grid w-full grid-cols-2 gap-6 md:grid-cols-5'>
      <Card className={classNames(cardClasses, 'col-span-2 md:col-span-1')}>
        <TitleAndSubCell
          title={
            isLoadingPrices ? (
              <Loading className={loaderClasses} />
            ) : (
              <DisplayCurrency
                className='text-xl'
                coin={BNCoin.fromDenomAndBigNumber(MARS.denom, BN_ONE.shiftedBy(MARS.decimals))}
                decimals={MARS.decimals}
              />
            )
          }
          sub='MARS Token Price'
        />
      </Card>
      <Card className={cardClasses}>
        <TitleAndSubCell
          title={
            isLoading ? (
              <Loading className={loaderClasses} />
            ) : (
              <FormattedNumber className='text-xl' amount={data} options={{ abbreviated: true }} />
            )
          }
          sub='Circulating Supply'
        />
      </Card>
      <Card className={cardClasses}>
        <TitleAndSubCell
          title={
            isLoading || isLoadingPrices ? (
              <Loading className={loaderClasses} />
            ) : (
              <DisplayCurrency
                className='text-xl'
                coin={BNCoin.fromDenomAndBigNumber(MARS.denom, new BigNumber(data))}
              />
            )
          }
          sub='Marketcap'
        />
      </Card>
      <Card className={cardClasses}>
        <TitleAndSubCell
          title={
            totalSupplyLoading ? (
              <Loading className={loaderClasses} />
            ) : (
              <FormattedNumber
                className='text-xl text-white/70'
                amount={totalSupply}
                options={{ abbreviated: true }}
              />
            )
          }
          sub='Total Supply'
        />
      </Card>
      <Card className={cardClasses}>
        <TitleAndSubCell
          title={
            totalSupplyLoading || isLoadingPrices ? (
              <Loading className={loaderClasses} />
            ) : (
              <DisplayCurrency
                className='text-xl text-white/70'
                coin={BNCoin.fromDenomAndBigNumber(
                  MARS.denom,
                  new BigNumber(totalSupply).shiftedBy(MARS.decimals),
                )}
              />
            )
          }
          sub='Fully Diluted Valuation'
        />
      </Card>
    </div>
  )
}
