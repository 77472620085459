import { Navigate, Outlet, Route, Routes } from 'react-router-dom'

import Layout from 'pages/_layout'
import Overview from 'pages/OverviewPage'
import Vesting from 'pages/VestingPage'

export default function Routing() {
  return (
    <Routes>
      <Route
        element={
          <Layout>
            <Outlet />
          </Layout>
        }
      >
        <Route path='/overview' element={<Overview />} />
        <Route path='/vesting' element={<Vesting />} />
        <Route path='/' element={<Overview />} />
        <Route path='/wallet/:address'>
          <Route path='overview' element={<Overview />} />
          <Route path='vesting' element={<Vesting />} />
          <Route path='' element={<Overview />} />
        </Route>
        <Route path='*' element={<Navigate to='/' />} />
      </Route>
    </Routes>
  )
}
