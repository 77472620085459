import { ENV } from 'constants/env'

export default async function getCirculatingSupply(): Promise<number> {
  const uri = new URL(ENV.CS_URL)

  try {
    const circulatingSupply: number = await fetch(uri.toString(), {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(async (res) => {
      const data = await res.json()
      return data
    })
    return circulatingSupply
  } catch (e) {
    console.error(e)
  }
  return 420_550_001.01536655
}
