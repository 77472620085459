import { useEffect, useState } from 'react'

import Metrics from 'components/Overview/Metrics'
import VestingChart from 'components/VestingChart'
import useAllPositions from 'hooks/useAllPositions'
import useConfig from 'hooks/useConfig'
import useStore from 'store'
import { convertAllPositionsToData } from 'utils/positions'

export default function Overivew() {
  const positionsStore = useStore((s) => s.positionsData)
  const { data: allPositions, isLoading: isLoadingAllPositions } = useAllPositions()
  const { data: config, isLoading: isLoadingConfig } = useConfig()
  const [positionsData, setPositionsData] = useState<PositionsData>({
    totalUnlocked: 0,
    totalVested: 0,
    totalClaimed: 0,
  })

  useEffect(() => {
    if (positionsData.chartData) return
    if (isLoadingAllPositions || isLoadingConfig) return

    if (positionsStore) {
      setPositionsData(positionsStore)
      return
    }
    const newData = convertAllPositionsToData(allPositions, config)
    useStore.setState({ positionsData: newData })
    setPositionsData(newData)
  }, [
    allPositions,
    isLoadingAllPositions,
    config,
    isLoadingConfig,
    positionsData.chartData,
    positionsStore,
  ])

  return (
    <>
      <Metrics />
      <VestingChart data={positionsData} title='Unlock Schedule' />
    </>
  )
}
