import { getMarsVestingQueryClient } from 'api/cosmwasm.client'
import iterateContractQuery from 'utils/iterateContractQuery'

export default async function getAllPositions(): Promise<ArrayOfPositionResponse> {
  try {
    const marsVestingQueryClient = await getMarsVestingQueryClient()

    return iterateContractQuery(marsVestingQueryClient.positions)
  } catch (e) {
    return new Promise((_, reject) => reject('No account found'))
  }
}
