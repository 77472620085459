import Intro from 'components/Intro'

export default function OverviewIntro() {
  return (
    <Intro
      text={
        <>
          This is the Mars Protocol <span className='text-white'>Vesting</span> UI. You can see all
          important metrics and information about the $MARS token vesting contract here.
        </>
      }
      bg='overview'
    />
  )
}
