import Overview from 'components/Overview'
import OverviewIntro from 'components/Overview/OverviewIntro'

export default function OverviewPage() {
  return (
    <>
      <OverviewIntro />
      <Overview />
    </>
  )
}
